import React from 'react'
import data from '../container/appConfig.json'
import Popup from './popup'
import Footer from './Footer/Footer'
import HeadingOfWebsite from './headingOfWebsite'

const EtarUpakram = (props) => {
	return (
		<div className='page-1'>
			<HeadingOfWebsite link={props.history} />

			<div className='backDrop'></div>

			<div className='pageMargin'>
				<div className='grid-x'>
					<div className='cell medium-3'></div>
					<div className='cell small-24 medium-18'>
						<div className='grid-x'>
							<div className='small-24 etarUpakramBox text-center'>
								<div className='etarUpakramHeading'>इतर उपक्रम</div>

								<div className='grid-x align-center'>
									{data.etarUpakram.map((q) => {
										return (
											<a
												href={q.link}
												target='blank'
												className='cell small-24 large-16 upakramBox'
											>
												<div className='upakramTitle'>{q.title}</div>
												<div className='upakramDescription'>
													{q.description.map((paragraph) => (
														<div>{paragraph}</div>
													))}
												</div>
											</a>
										)
									})}
								</div>
							</div>
						</div>
					</div>

					<Popup />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default EtarUpakram
