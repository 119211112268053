import React from 'react'
import { useClearCache } from 'react-clear-cache'

const Footer = (props) => {
	const { isLatestVersion, emptyCacheStorage } = useClearCache()

	return (
		<footer className='hide-for-small-only'>
			<div className='grid-x'>
				<div className='cell shrink'>
					Copyright © 2020 -
					<a
						className='link-dark'
						href='https://www.jnanaprabodhini.org/'
						target='_blank'
					>
						ज्ञान प्रबोधिनी
					</a>
					. All Rights Reserved.&nbsp;
				</div>
				<div className='cell shrink'>
					{!isLatestVersion && (
						<>
							A new app version is available. ({' '}
							<a
								className='link-dark'
								href='#'
								onClick={(e) => {
									e.preventDefault()
									emptyCacheStorage()
								}}
							>
								Update App &nbsp;
							</a>
							)
						</>
					)}
				</div>
				<div className='cell auto'></div>
				<div className='cell shrink'>
					<a
						href='https://www.facebook.com/DrVikramSarabhaiDal/'
						target='_blank'
						className='link-dark pr-md'
					>
						<i class='fab fa-facebook pr-md'></i>
						फेसबुक {/* Facebook */}
					</a>
					<a
						href='https://www.instagram.com/jp_vijnanadal/'
						target='_blank'
						className='link-dark'
					>
						<i class='fab fa-instagram pr-md'></i>
						इंस्टाग्राम {/* Instagram */}
					</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer
