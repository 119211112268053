import React, { useState, useEffect } from "react";
import data from "../../../container/appConfig.json";
import Popup from "../../popup";
import HeadingOfWebsite from "../../headingOfWebsite";
import Footer from "../../Footer/Footer";

const SubQuestion = (props) => {
  const backPage = () => {
    props.history.push("/question");
  };

  const thisPage = (id) => {
    props.history.push("/subquestion/" + id);
  };

  const selectedQuestionUIID = props.match.params.id2;

  const getDigitsOfUIID = (UIID) => UIID.toString().length;

  const MainQuestionData = data.questions.filter(function (qt) {
    return qt.questionType === "main";
  });

  const QuestionData = (UIID) => {
    const reqQuestion = data.questions.filter(function (rq) {
      return rq.uiid == UIID;
    });
    return reqQuestion;
  };

  const selectedQuestionData = QuestionData(selectedQuestionUIID)[0];

  const [videoid, setVideoId] = useState(0);

  const dropDown = () => {
    setVideoId(videoid + 1);

    var phoneSub = document.getElementsByClassName("sub-option");
    var phoneMain = document.getElementsByClassName("phone-main-option");
    if (phoneSub[0].style.display == "block") {
      phoneSub[0].style.display = "none";
      phoneMain[0].children[0].children[3].style.transform = "rotateZ(0deg)";
    } else {
      phoneSub[0].style.display = "block";
      phoneMain[0].children[0].children[3].style.transform = "rotateZ(180deg)";
    }
  };

  const showDesktopDropdown = (selectedNumber) => {
    if (selectedNumber < 100) {
      var Sub = document.getElementsByClassName("sub-option");

      var dropdownIcon = document.getElementsByClassName("dropdownIcon");
      if (Sub[selectedNumber].style.display == "block") {
        Sub[selectedNumber].style.display = "none";
        dropdownIcon[selectedNumber - 1].style.transform = "rotate(0deg)";
        if (selectedMainQuestionUUID == selectedNumber) {
          dropdownIcon[selectedNumber - 1].style.color = "#f9698d";
        } else {
          dropdownIcon[selectedNumber - 1].style.color = "#685968";
        }
      } else {
        Sub[selectedNumber].style.display = "block";
        dropdownIcon[selectedNumber - 1].style.transform = "rotate(180deg)";
        dropdownIcon[selectedNumber - 1].style.color = "#f9698d";
      }
    }
  };

  useEffect(() => {
    showDesktopDropdown(selectedMainQuestionUUID);
  }, []);

  const hideDropDown = (id) => {
    if (id != -1) {
      props.history.push("/subquestion/" + id);
      setVideoId(id + 1);
    }

    var phoneSub = document.getElementsByClassName("sub-option");
    phoneSub[0].style.display = "none";
    var phoneMain = document.getElementsByClassName("phone-main-option");

    phoneMain[0].children[0].children[3].style.transform = "rotateZ(0deg)";
  };

  var selectedMainQuestionUUID;
  if (selectedQuestionUIID > 100) {
    const digits = getDigitsOfUIID(selectedQuestionUIID);
    const divident = Math.pow(10, digits - 1);

    selectedMainQuestionUUID = parseInt(selectedQuestionUIID / divident);
  } else {
    selectedMainQuestionUUID = selectedQuestionUIID;
  }

  var selectedSubQuestionNumber;
  if (selectedQuestionUIID > 100) {
    const digits = getDigitsOfUIID(selectedQuestionUIID);
    const divident = Math.pow(10, digits - 1);

    selectedSubQuestionNumber = parseInt(selectedQuestionUIID % divident);
  }

  const [popup, setPopup] = useState(0);

  const showPudhilPrashnBox = () => {
    var popup = document.getElementsByClassName("pudhilPrashnPopup");
    popup[0].style.transform = "translate(-50%,-50%) scale(1)";
    var backdrop = document.getElementsByClassName("customPopupBackdrop");
    backdrop[0].style.display = "block";
  };

  const hidePudhilPrashnBox = (qt) => {
    if (qt != -1) {
      var selectedQuestionUIIDId = QuestionData(qt).map((q) => q.uiid);
      props.history.push("/subquestion/" + selectedQuestionUIIDId);
    }

    var popup = document.getElementsByClassName("pudhilPrashnPopup");
    popup[0].style.transform = "translate(-50%,-50%) scale(0)";
    var backdrop = document.getElementsByClassName("customPopupBackdrop");
    backdrop[0].style.display = "none";
  };

  const showShabdkoshPopup = () => {
    var popup = document.getElementsByClassName("shabdkoshPopup");

    popup[0].style.transform = "translate(-50%,-50%) scale(1)";
    var backdrop = document.getElementsByClassName("customPopupBackdrop");
    backdrop[0].style.display = "block";
  };

  const hideShabdkoshPopup = () => {
    var popup = document.getElementsByClassName("shabdkoshPopup");
    popup[0].style.transform = "translate(-50%,-50%) scale(0)";
    var backdrop = document.getElementsByClassName("customPopupBackdrop");
    backdrop[0].style.display = "none";
  };

  return (
    <div className="page-3">
      <div className="grid-x">
        <HeadingOfWebsite link={props.history} />

        <div className="cell small-24  kontaPrashnBox show-for-custom customMarginT">
          <div className="grid-x">
            <div
              className="cell small-12 text-center kontaPrashn borderWhite"
              onClick={backPage}
            >
              सगळे प्रश्न
            </div>
            <div
              className="cell small-12 text-center kontaPrashn borderWhite"
              onClick={showPudhilPrashnBox}
            >
              पुढील प्रश्न
            </div>
          </div>
        </div>

        <div
          className="cell small-24 phone-main-option show-for-custom"
          onClick={dropDown}
        >
          <div className="grid-x  cursor align-middle">
            <div className="cell small-5 text-center">
              <img
                src={QuestionData(selectedMainQuestionUUID)[0].iconImage}
                alt="image"
                className="turnIntoWhite"
              />
            </div>
            <div className="cell shrink questionNo small-2">
              {selectedQuestionData.questionNumber}
            </div>

            <div className="cell small-13">{selectedQuestionData.title}</div>
            <div className="cell small-4 text-center align-self-middle">
              <i className="fas fa-chevron-right dropdown-icon"></i>
            </div>
          </div>
        </div>

        <div className="small-24 sub-option">
          {QuestionData(selectedMainQuestionUUID).map((i) =>
            i.subQuestions.map((sub) => {
              return (
                <div
                  className="grid-x phone-sub-option-block"
                  key={sub}
                  onClick={() => hideDropDown(sub)}
                >
                  <div className="cell small-2 text-center"></div>

                  <div className="cell auto text-left">
                    <div className="grid-x">
                      <span className="cell shrink questionNo">
                        {QuestionData(sub).map(
                          (question) => question.questionNumber
                        )}
                      </span>
                      <span className="cell auto">
                        {QuestionData(sub).map((question) => question.title)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="cell small-5 video-list-box text-center  pageMargin hide-for-custom">
          {/* /////////////////////////////desktop start////////////////////////////////////      */}

          {MainQuestionData.map((question, index) => {
            return (
              <div key={index}>
                <div className="grid-x main-option align-right questions">
                  <div className="cell shrink align-self-middle">
                    <img
                      src="/images/videoIconDark.svg"
                      className="video-dark-icon padR21"
                      alt="video-icon"
                      style={
                        selectedMainQuestionUUID == question.uiid
                          ? { filter: "none" }
                          : { filter: "brightness(0.5)" }
                      }
                    />
                  </div>

                  <div
                    className="cell auto text-left"
                    onClick={() => {
                      thisPage(question.uiid);
                      showDesktopDropdown(question.uiid);
                    }}
                  >
                    <span
                      className="video-list-option "
                      style={
                        selectedMainQuestionUUID == question.uiid
                          ? { color: "#f9698d" }
                          : { transform: "#685968" }
                      }
                    >
                      {question.title}
                    </span>
                  </div>
                  <div
                    className="cell small-2 align-self-middle dropdownIcon"
                    onClick={() => showDesktopDropdown(question.uiid)}
                  >
                    <i className="fas fa-chevron-right dropdown-icon"></i>
                  </div>
                </div>

                <div className="grid-x sub-option">
                  {MainQuestionData[index].subQuestions.map((sub, i) => {
                    return (
                      <div className="grid-x" key={sub}>
                        <a
                          className="cell auto text-left video-list-option colorChange deskTopsub "
                          onClick={() => thisPage(sub)}
                        >
                          <div className="grid-x questions">
                            <div className="cell shrink questionNo ">
                              {QuestionData(sub).map((q) => {
                                return (
                                  <div
                                    style={
                                      q.uiid == selectedQuestionUIID
                                        ? { color: "#f9698d" }
                                        : { color: "#685968" }
                                    }
                                    key={q.uiid}
                                    className="hoverPink"
                                  >
                                    {q.questionNumber ? q.questionNumber : ""}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="cell auto  ">
                              {QuestionData(sub).map((q) => {
                                return (
                                  <div
                                    style={
                                      q.uiid == selectedQuestionUIID
                                        ? { color: "#f9698d" }
                                        : { color: "#685968" }
                                    }
                                    className="hoverPink"
                                    key={q.uiid}
                                  >
                                    {q.title}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>

                <span className="black-line"></span>
              </div>
            );
          })}
        </div>

        <div className="cell customBoxWidth marginTonlyforLarge">
          <div className="grid-x">
            <div className="cell small-24 video-box">
              {selectedQuestionData.contentType == "video" ? (
                <iframe
                  title="v"
                  width="100%"
                  height="100%"
                  src={selectedQuestionData.contentLink}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <div>
                  <img src={selectedQuestionData.contentLink} width="100%" />
                </div>
              )}
            </div>

            <div className="cell small-24 video-info-page3-box hide-for-custom">
              <div className="grid-x">
                <div className="cell auto"></div>
                <div className="pradarshanNameSmall">
                  डॉ. विक्रम साराभाई दल विज्ञान प्रदर्शन
                </div>
              </div>
              <div className="grid-x">
                <div className="cell small-3 text-center ">
                  <img
                    src={QuestionData(selectedMainQuestionUUID)[0].iconImage}
                    alt="image"
                    className="turnIntoWhite"
                  />
                </div>
                <div className="cell auto">
                  <div className="cell  shrink text-left video-name-page-3">
                    {selectedQuestionData.title}
                  </div>

                  <div className="video-text">
                    {selectedQuestionData.description.map((paragraph) => (
                      <div style={{ marginBottom: "5px" }}>{paragraph}</div>
                    ))}
                    {selectedQuestionData.sandarbha[0].name.length > 0 ? (
                      <div className="pt-md">संदर्भ:</div>
                    ) : (
                      ""
                    )}
                    {selectedQuestionData.sandarbha
                      ? selectedQuestionData.sandarbha.map((reference) => (
                          <div style={{ marginBottom: "5px" }}>
                            <a
                              className="link-dark"
                              href={reference.link}
                              target="_blank"
                            >
                              {reference.name}
                            </a>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <Popup
              show={popup}
              pageNumber="3"
              selectedQ={selectedQuestionUIID}
            />
          </div>
        </div>
        <div className="cell small-5 text-left pageMargin hide-for-custom">
          <div className="grid-x">
            <div className="small-24 pudhilPrashnBox">
              <span className="pudhil-prashn-heading">
                <span className="video-icon padR18">
                  <img src="/images/videoIcon.svg" alt="image" />
                </span>
                पुढील प्रश्न
              </span>

              {selectedQuestionData.nextQuestions.map((nq) => {
                return (
                  <span className="pudhil-prashn-text " key={nq}>
                    <span
                      onClick={() => {
                        thisPage(nq);
                        showDesktopDropdown(nq);
                      }}
                    >
                      <div className="grid-x">
                        <div className="cell shrink questionNo">
                          {QuestionData(nq).map((q) => q.questionNumber)}
                        </div>
                        <div className="cell auto">
                          {QuestionData(nq).map((q) => q.title)}
                        </div>

                        <div className="pinkLine"></div>
                      </div>
                    </span>
                  </span>
                );
              })}
            </div>

            <div className="cell small-24 shabdkosh-box hide-for-small-only">
              <span className="shabdkosh-heading">
                <span className="shabdakosh-icon padR25">
                  <img src="/images/shabdkoshIcon.svg" alt="" />
                </span>
                शब्दकोश
              </span>
              {selectedQuestionData.shabdkaosh.map((shabdkosh) => {
                return (
                  <div>
                    <span className="shabdkosh-sub-heading">
                      {shabdkosh.word}
                    </span>
                    <span className="page-3-small-text padB20 padR18">
                      {shabdkosh.meaning}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="cell small-24 show-for-custom video-info-phone-box">
          {selectedQuestionData.description.map((paragraph) => (
            <>
              <div style={{ marginBottom: "5px" }}>{paragraph}</div>
              <div className="pt-md">संदर्भ:</div>
            </>
          ))}

          {selectedQuestionData.sandarbha
            ? selectedQuestionData.sandarbha.map((reference) => (
                <>
                  <div style={{ marginBottom: "5px" }}>
                    <a
                      className="link-dark"
                      href={reference.link}
                      target="_blank"
                    >
                      {reference.name}
                    </a>
                  </div>
                </>
              ))
            : ""}
        </div>
      </div>

      <div className="elipse show-for-custom">
        <img src="/images/elipse.svg" />
        <img
          src="/images/shabdkoshPhoneIcon.svg"
          className="shabdkoshPhoneIcon"
          onClick={showShabdkoshPopup}
        />
      </div>
      <div
        className="customPopupBackdrop"
        onClick={() => {
          hideShabdkoshPopup();
          hidePudhilPrashnBox(-1);
        }}
      ></div>
      <div className="grid-x pudhilPrashnPopup">
        <div className="cell small-24 pudhilPrashnHeadingBlock">
          <div className="grid-x">
            <span className="cell auto text-left popupHeading">
              पुढील प्रश्न
            </span>
            <span className="cell shrink text-right align-self-middle">
              <span
                onClick={() => {
                  hidePudhilPrashnBox(-1);
                }}
              >
                <img src="/images/cross.svg" className="popupCross" />
              </span>
            </span>
          </div>
        </div>
        {selectedQuestionData.nextQuestions.map((q) => {
          return (
            <div
              className="cell small-24 pudhilPrashnBlock "
              onClick={() => {
                hidePudhilPrashnBox(q);
                hideDropDown(-1);
              }}
            >
              {QuestionData(q).map((q) => q.title)}
            </div>
          );
        })}
      </div>

      <div className="grid-x shabdkoshPopup">
        <div className="cell small-24 shabdkoshHeadingBlock">
          <div className="grid-x">
            <span className="cell auto text-left popupHeading">शब्दकोश</span>
            <span className="cell shrink text-right align-self-middle">
              <span onClick={hideShabdkoshPopup}>
                <img src="/images/cross.svg" className="popupCross" />
              </span>
            </span>
          </div>
        </div>

        <div className="phoneShabdkosh">
          {selectedQuestionData.shabdkaosh.map((shabdkosh) => {
            return (
              <div>
                <div className="phoneShabdkoshWord">{shabdkosh.word}</div>
                <div className="phoneShabdkoshMeaning">{shabdkosh.meaning}</div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubQuestion;
