import React from 'react'
import data from './../container/appConfig.json'
import { useState, useEffect } from 'react'
import { setFeedback } from './../database/database'

const Popup = (props) => {
	const QuestionData = (UIID) => {
		const reqQuestion = data.questions.filter(function (rq) {
			return rq.uiid == UIID
		})
		return reqQuestion
	}

	/*Popup state */

	const [isLoading, setIsLoading] = useState(false)

	const [isPopupOpen, setIsPopupOpen] = useState(false)

	const [selectedPopup, setSelectedPopup] = useState(0)

	const [selectedMukhyaPrashn, setSelectedMukhyaPrashn] = useState(0)

	const [subQuestionSelected, setSubQuestionSelected] = useState(false)

	const [formSubmitError, setFormSubmitError] = useState('')

	const [errorMessages, setErrorMessages] = useState({
		contactNumber: '',
		feedbackTitle: '',
		feedback: '',
		mainQuestion: '',
	})
	const [isFormSubmitted, setisFormSubmitted] = useState(false)
	const [formMainQuestion, setFormMainQuestion] = useState('')
	const [formSubQuestion, setFormSubQuestion] = useState('')
	const [formContactNumber, setFormContactNumber] = useState('')
	const [formFeedbackTitle, setFormFeedbackTitle] = useState('')
	const [formFeedback, setFormFeedback] = useState('')

	/* Methods */
	const formSubmit = (e) => {
		if (formContactNumber.length < 10) {
			setErrorMessages({
				...errorMessages,
				contactNumber: 'Please enter valid email or Password',
			})
		}

		setisFormSubmitted(true)

		if (
			errorMessages.contactNumber.length === 0 &&
			errorMessages.feedback.length === 0 &&
			errorMessages.feedbackTitle.length === 0 &&
			errorMessages.mainQuestion.length === 0 &&
			formContactNumber.length > 0 &&
			formFeedback.length > 0 &&
			formFeedbackTitle.length > 0 &&
			formContactNumber.length >= 10
		) {
			setIsLoading(true)
			setFeedback(
				(error) => {
					if (error === undefined) {
						setIsLoading(false)
						setFormSubmitError('प्रतिसाद मिळाला,धन्यवाद !')
					} else {
						setFormSubmitError('something went wrong')
					}
					if (!isLoading) {
						setFormContactNumber('')
						setFormFeedback('')
						setFormFeedbackTitle('')
						setSelectedMukhyaPrashn(0)
					}
				},
				formContactNumber,
				formFeedback,
				formFeedbackTitle,
				formMainQuestion,
				formSubQuestion
			)
			setisFormSubmitted('submitted')
		} else {
			setisFormSubmitted('notSubmitted')
		}
	}

	/* 
		Checks whether the phone number is 10 digit long
		Phone number should not start with 0 
		All characters should be numbers from 0 to 9 
		Sets an error message if the contact number is not valid
	*/
	const formValidateContactNumber = () => {
		const regEx = /^^(?:\d{0,10}|\w+@\w+\.\w{2,3})$/g
		let errorMessage = ''
		if (!regEx.test(formContactNumber)) {
			errorMessage = 'Please enter a valid contact number or email'
		}
		setErrorMessages({
			...errorMessages,
			contactNumber: errorMessage,
		})
	}

	const formValidateFeedbackTitle = () => {
		// Length should be <= 100 characters & > 0
		// should contain only english / marathi alphabets & space, comma, full stop, question mark
		const regEx = /^[^<>%$#()+*]*$/ /* /^[\w,.!? -;@]{0,100}$/g*/
		let errorMessage = ''
		if (!regEx.test(formFeedbackTitle)) {
			errorMessage = 'Please dont use restricted symbols'
		}
		setErrorMessages({
			...errorMessages,
			feedbackTitle: errorMessage,
		})
	}

	const formValidateFeedback = () => {
		const regEx = /^[^<>%$#()+*]*$/
		let errorMessage = ''
		if (!regEx.test(formFeedback)) {
			errorMessage = 'Please dont use restricted symbols'
		}
		setErrorMessages({
			...errorMessages,
			feedback: errorMessage,
		})
	}

	const mainQuestion = data.questions.filter(function (qt) {
		return qt.questionType === 'main'
	})

	useEffect(() => {
		formValidateContactNumber(formContactNumber)
	}, [formContactNumber])

	useEffect(() => {
		formValidateFeedback(formFeedback)
	}, [formFeedback])

	useEffect(() => {
		formValidateFeedbackTitle(formFeedbackTitle)
	}, [formFeedbackTitle])

	useEffect(() => {
		if (selectedPopup > 0) {
			setIsPopupOpen(true)
		} else {
			setIsPopupOpen(false)
		}
	}, [selectedPopup])

	useEffect(() => {
		if (!isPopupOpen) {
			setSelectedPopup(0)
		}
	}, [isPopupOpen])

	return (
		<>
			{props.pageNumber !== '3' ? (
				<div className='cell small-3 hide-for-small-only '>
					<div className='grid-x'>
						<div
							className='cell small-24 text-center right-side-box pratisad-text'
							onClick={() => setSelectedPopup(1)}
						>
							प्रतिसाद
						</div>
						<div
							className='cell small-24 text-center right-side-box  bgBlackWhite pratisad-text'
							onClick={() => setSelectedPopup(2)}
						>
							देणगी
						</div>

						<a
							href='https://dl.flipkart.com/dl/science-experiment-booklets/p/itmdeab3cbc746d9?pid=RBKGF3Q6GH9X7WA3&cmpid=product.share.pp'
							target='_blank'
							className='cell small-24 text-center right-side-box pratisad-text'
						>
							पुस्तिका
						</a>
					</div>
				</div>
			) : (
				<div className='cell small-24 page-3-footer  hide-for-custom'>
					<div className='grid-x'>
						<div
							className='cell small-8 text-center footer-box pratisad-text'
							onClick={() => setSelectedPopup(1)}
						>
							प्रतिसाद
						</div>
						<div
							className='cell small-8 text-center footer-box bgWhite pratisad-text'
							onClick={() => setSelectedPopup(2)}
						>
							देणगी
						</div>

						<a
							href='https://dl.flipkart.com/dl/science-experiment-booklets/p/itmdeab3cbc746d9?pid=RBKGF3Q6GH9X7WA3&cmpid=product.share.pp/'
							target='_blank'
							className='cell small-8 text-center footer-box pratisad-text'
						>
							पुस्तिका
						</a>
					</div>
				</div>
			)}
			<div
				className={
					props.pageNumber === '3'
						? 'cell small-24 show-for-custom footer-options '
						: 'cell small-24 show-for-small-only footer-options '
				}
			>
				<div className='grid-x'>
					<div
						className='cell small-8 text-center footer-option'
						onClick={() => setSelectedPopup(1)}
					>
						प्रतिसाद
					</div>
					<div
						className='cell small-8 text-center footer-option bgFaint'
						onClick={() => setSelectedPopup(2)}
					>
						देणगी
					</div>

					<a
						href='https://dl.flipkart.com/dl/science-experiment-booklets/p/itmdeab3cbc746d9?pid=RBKGF3Q6GH9X7WA3&cmpid=product.share.pp'
						target='_blank'
						className='cell small-8 text-center footer-option'
					>
						पुस्तिका
					</a>
				</div>
			</div>
			<div
				className='popupBackdrop'
				style={isPopupOpen > 0 ? { display: 'block' } : { display: 'none' }}
				onClick={() => setIsPopupOpen(false)}
			></div>
			<div
				className='grid-x popupBox'
				style={
					isPopupOpen
						? { transform: 'translate(-50%,-50%) scale(1)' }
						: { transform: 'translate(-50%,-50%) scale(0)' }
				}
			>
				<div className='cell small-24 popupHeadingBlock'>
					<div className='grid-x'>
						<span className='cell auto text-left popupHeading'>
							{selectedPopup === 1
								? 'प्रतिसाद '
								: selectedPopup === 2
								? 'देणगी'
								: selectedPopup === 3
								? 'पुस्तिका'
								: ''}
						</span>
						<span className='cell shrink text-right align-self-middle'>
							<span
								onClick={() => {
									{
										setIsPopupOpen(false)
										setFormSubmitError('')
									}
								}}
							>
								<img src='/images/cross.svg' className='popupCross' />
							</span>
						</span>
					</div>
				</div>
				{selectedPopup === 1 ? (
					<div className='cell small-24 pratisadForm'>
						<div className='grid-x'>
							<div className='cell small-24 medium-12'>
								<div className='popupText'>
									तुम्ही इथे संकेतस्थळावरील कोणत्याही चित्रफिती वरचा प्रतिसाद
									देऊ शकता किवा तुम्हाला पडलेला प्रश्न विचारू शकता. संपूर्ण
									संकेतस्थळविषयी सुद्धा आपला प्रतिसाद दद्यावा.
								</div>
								<div className='mukhyaPrashnNiwadaContainer'>
									<select
										className={
											isLoading
												? 'mukhyaPrashnNiwadaBox elementDisabled'
												: 'mukhyaPrashnNiwadaBox'
										}
										onChange={(e) => {
											setFormMainQuestion(
												e.target.value > 0
													? mainQuestion[e.target.value - 1].title
													: 'संपूर्ण संकेतस्थळाबद्दल प्रतिसाद'
											)

											setSelectedMukhyaPrashn(
												e.target.value > 0 ? e.target.value : 0
											)
										}}
									>
										<option
											value=''
											selected={isFormSubmitted ? 'selected' : ''}
										>
											मुख्य प्रश्न निवडा
										</option>
										<option>संपूर्ण संकेतस्थळाबद्दल प्रतिसाद </option>
										{mainQuestion.map((question) => {
											return (
												<option value={question.uiid} key={question.uiid}>
													{question.title}
												</option>
											)
										})}
									</select>
									<i class='fas fa-arrow-down downArrow'></i>
								</div>
								{selectedMukhyaPrashn > 0 ? (
									<div className='mukhyaPrashnNiwadaContainer'>
										<select
											className={
												isLoading
													? 'mukhyaPrashnNiwadaBox elementDisabled'
													: 'mukhyaPrashnNiwadaBox'
											}
											onChange={(e) => {
												selectedMukhyaPrashn > 0
													? setFormSubQuestion(e.target.value)
													: setFormSubQuestion('')
											}}
										>
											<option value='' disabled selected>
												उपप्रश्न निवडा
											</option>
											{QuestionData(selectedMukhyaPrashn)[0].subQuestions.map(
												(subQuestion) => {
													return (
														<option>
															{QuestionData(subQuestion)[0].title}
														</option>
													)
												}
											)}
										</select>
										<i class='fas fa-arrow-down downArrow'></i>
									</div>
								) : (
									''
								)}
								<div className='pratisad-form-error-message'>
									{errorMessages.mainQuestion.length > 0
										? errorMessages.mainQuestion
										: ''}
								</div>
							</div>
							<div className='cell small-24 medium-12 padL23'>
								<form>
									<fieldset
										disabled={isLoading ? 'disabled' : ''}
										onFocus={() => setFormSubmitError('')}
									>
										<span className='pratisadFormTitle'>
											संपर्क क्र / ई-मेल
										</span>
										<input
											type='text'
											required='required'
											className='pratisadFormInputSmallBox'
											value={formContactNumber}
											onChange={(e) => {
												setFormContactNumber(e.target.value)
											}}
										></input>
										<div className='pratisad-form-error-message'>
											{errorMessages.contactNumber.length > 0 &&
											formContactNumber.length > 0
												? errorMessages.contactNumber
												: ''}
										</div>

										<span className='pratisadFormTitle'>प्रतिसाद / प्रश्न</span>
										<input
											type='text'
											required='required'
											className='pratisadFormInputSmallBox'
											value={formFeedbackTitle}
											onChange={(e) => {
												setFormFeedbackTitle(e.target.value)
											}}
										></input>
										<div className='pratisad-form-error-message'>
											{errorMessages.feedbackTitle.length > 0
												? errorMessages.feedbackTitle
												: ''}
										</div>

										<span className='pratisadFormTitle'>
											संकेतस्थळी विषयी प्रतिसाद / प्रश्न
										</span>
										<textarea
											type='text'
											required='required'
											className='pratisadFormInputSmallBox pratisadFormInputLargeBox'
											value={formFeedback}
											onChange={(e) => {
												setFormFeedback(e.target.value)
											}}
										></textarea>
										<div className='pratisad-form-error-message'>
											{errorMessages.feedback.length > 0
												? errorMessages.feedback
												: ''}
										</div>

										<div
											className={
												isLoading
													? 'popupButton centerForSmallSize mt-sm formButton elementDisabled'
													: 'popupButton centerForSmallSize mt-sm formButton '
											}
											onClick={formSubmit}
											disabled='disabled'
										>
											पाठवा
										</div>
									</fieldset>
									{setFormSubmitError !== '' ? (
										<div
											style={{ color: 'green', marginTop: '10px' }}
											className='pratisad-form-error-message centerForSmallSize'
										>
											{formSubmitError}
										</div>
									) : (
										''
									)}
								</form>
							</div>
						</div>
					</div>
				) : selectedPopup === 3 ? (
					<div className='contentBox'>
						<div className='cell small-24 popupText '>लवकरच येत आहे....</div>
						{/* <div className='cell small-24 text-center padT20'>
							<img src='/images/amazon.svg' />
						</div>
						<div className='cell small-24 text-center popupText pad15'>
							वर उपलब्ध
						</div>
						<a href={data.amazonLink} target='blank'>
							<button className='popupButton buttonPosition'>खरेदी</button>
						</a> */}
					</div>
				) : (
					<div className='contentBox'>
						<div className='cell small-24 popupText '>
							<p>
								<b>ज्ञान प्रबोधिनी युवक विभाग</b>
							</p>
							<p>डॉ. विक्रम साराभाई दल आयोजित</p>
							<p>'साथीच्या रोगांचे आयाम- १६वे विज्ञान प्रदर्शन'</p>
							<p>
								दरवर्षी प्रमाणे यावर्षी देखील विज्ञान प्रदर्शन सर्वांपर्यंत
								पोहोचवण्याच्या हेतूने यावर्षीचे विज्ञान प्रदर्शन पूर्णतः online
								माध्यमातून करणार आहोत. त्यासाठी विविध प्रकारची साधने तयार करावी
								लागणार आहेत. ज्यामध्ये मुख्यतः संकेतस्थळ निर्मिती व व्यवस्थापन
								आणि व्हिडिओ चे संपादन असेल. यासाठी आम्हाला आपल्या मदतीची
								आवश्यकता आहे.
							</p>
							<p>
								देणगी साठी खाली दिलेली link उघडल्यावर ज्ञान प्रबोधिनी कडून
								वापरल्या जाणाऱ्या etech या website वर जाईल. ही website विश्वसनीय
								आहे.
							</p>
						</div>
						<a href={data.denagiLink} target='blank'>
							<button className='popupButton buttonPosition marginT20'>
								दान करा
							</button>
						</a>
					</div>
				)}
				{isLoading ? (
					<div className='LoaderPosition'>
						<div className='lds-ring'>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</>
	)
}

export default Popup
