import React from "react";
import HeadingOfWebsite from "./headingOfWebsite";
import { MapInteractionCSS } from "react-map-interaction";

const SankalpWruksh = (props) => (
  <>
    <HeadingOfWebsite size="small" link={props.history} />
    <div
      style={{
        height: "100vh",
      }}
    >
      <MapInteractionCSS
        showControls={true}
        defaultValue={{
          scale: 1,
          translation: { x: 0, y: 200 },
        }}
        maxScale={5}
        minScale={0.5}
      >
        <img src="images/sankalpana-vruksha.svg" alt="sankalpWruksh" />
      </MapInteractionCSS>
    </div>
  </>
);

export default SankalpWruksh;
