import * as firebase from 'firebase'
import 'firebase/auth'

var firebaseConfig = {
	apiKey: 'AIzaSyBsT31niVK3Vo3b1cu6593iyvpnGm4am7Q',
	authDomain: 'prabodhini.firebaseapp.com',
	databaseURL: 'https://prabodhini.firebaseio.com',
	projectId: 'prabodhini',
	storageBucket: 'prabodhini.appspot.com',
	messagingSenderId: '16177960988',
	appId: '1:16177960988:web:62d8595f6be2358c0827d9',
	measurementId: 'G-TKJLT37JL1',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const db = firebase.firestore()

const setFeedback = (
	getLoaderStatus,
	contactNumber,
	feedback,
	feedbackTitle,
	mainQuestion,
	subQuestion
) => {
	const timestamp = Date.now()
	db.collection('feedbacks')
		.add({
			contactNumber,
			feedback,
			feedbackTitle,
			mainQuestion,
			subQuestion,
			receivedOn: new Intl.DateTimeFormat('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			}).format(timestamp),
		})
		.then((doc) => {
			if (doc.id.length > 0) {
				getLoaderStatus(undefined)
			} else {
				getLoaderStatus('error')
			}
		})

		.catch((error) => {
			getLoaderStatus('error')
		})
}
export { setFeedback, db }
