import React from "react";
import data from "../../container/appConfig.json";

import Popup from "../popup";
import Footer from "../Footer/Footer";

import HeadingOfWebsite from "../headingOfWebsite";

const MainQuestion = (props) => {
  const phoneimagepath = "/images/phone-question-icon/";
  const laptopimagepath = "/images/question-icon/";

  const homePageHandler = () => {
    props.history.replace("/");
  };

  const nextPage = (id) => {
    props.history.push("/subquestion/" + id);
  };

  const mainQuestion = data.questions.filter(function (qt) {
    return qt.questionType === "main";
  });

  return (
    <div className="page-2">
      <div className="grid-x">
        <HeadingOfWebsite link={props.history} />

        <div className="cell small-24 show-for-small-only  pradarshanName-small-box marginT75">
          <div className="grid-x">
            <div className="cell shrink text-center" onClick={homePageHandler}>
              <span className="small-arrow-box">
                <img
                  src="/images/BackArrowSmall.svg"
                  className="bArrowSmall"
                  alt=""
                />
              </span>
            </div>

            <div className="cell auto text-center">
              <span className="pradarshanName-small-box-middle">
                डॉ. विक्रम साराभाई दल विज्ञान प्रदर्शन
              </span>
            </div>

            <div className="cell shrink text-center">
              <span
                className="small-arrow-box"
                onClick={() => nextPage(parseFloat("1"))}
              >
                <img
                  src="/images/NextArrowSmall.svg"
                  alt=""
                  className="bArrowSmall"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="cell small-24 show-for-small-only question-section">
          <div className="grid-x ">
            {mainQuestion.map((element, index) => {
              return (
                <div
                  className="small-24 qt"
                  key={index}
                  onClick={() => nextPage(index + 1)}
                >
                  <div className="grid-x">
                    <div className="cell small-7 text-center">
                      <img src={phoneimagepath + (index + 1) + ".svg"} alt="" />
                    </div>
                    <div className="cell small-17 text-left align-self-middle padR50">
                      <span className="qt-text">{element.title}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="pageMargin">
          <div className="grid-x">
            <div className="cell small-3 text-right hide-for-small-only"></div>

            <div className="cell small-18 hide-for-small-only">
              <div className="grid-x">
                <div className="cell small-24 question-boxes">
                  <div className="grid-x">
                    {mainQuestion.map((element, index) => {
                      return (
                        <div
                          className="cell large-8 medium-12"
                          key={index}
                          onClick={() => nextPage(index + 1)}
                        >
                          <span className="grid-x question-box">
                            <div className="cell small-24 text-center">
                              <div className="grid-x">
                                <div className="cell shrink">
                                  <img
                                    src={laptopimagepath + (index + 1) + ".svg"}
                                    className="question-icon"
                                    alt=""
                                  />
                                </div>
                                <div className="cell auto text-left">
                                  <span className="question-text">
                                    {element.title}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span className="AddIconBox">
                              <img
                                src="/images/Addicon.svg"
                                className="AddIcon"
                                alt="ADD"
                              />
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="cell small-24 padB25">
                  <div className="grid-x">
                    <div
                      className="cell small-3 arrow-box"
                      onClick={homePageHandler}
                    >
                      <img
                        src="/images/BackArrow.svg"
                        alt=""
                        className="arrow"
                      />
                    </div>
                    <div className="cell small-18 video-info-box">
                      <span className="pradarshanName">
                        डॉ. विक्रम साराभाई दल विज्ञान प्रदर्शन
                      </span>
                      <span className="olakh">प्रदर्शनाची ओळख</span>
                      <span className="video-text">
                        <p>
                          गणेशोत्सवात ज्ञान प्रबोधिनीच्या पुण्यातील वास्तूमध्ये
                          गेल्या १५ वर्षांपासून युवक विभागातील विज्ञान दले
                          विज्ञान प्रदर्शनांचे आयोजन करत आहेत. प्रत्येक
                          वर्षीच्या प्रदर्शनात एक विषय घ्यायचा आणि तो विषय ४० ते
                          ५० प्रयोगांमधून विज्ञानाचे विद्यार्थी नसणाऱ्यांनाही
                          समजतील अशी दैनंदिन जीवनातील रुपके वापरत, सोप्या मराठी
                          भाषेत समजावून सांगायचा हे या विज्ञान प्रदर्शनांचं
                          वैशिष्ट्य. आत्तापर्यंत विज्ञानाच्या विविध शाखांमधील
                          रसायनशास्त्र, वीज, जैववैविध्य, दाब, गती, उष्णता,
                          खेळामधील विज्ञान अशा वेगवेगळ्या विषयांवर प्रदर्शन झालं
                          आहे. दरवर्षी जवळ जवळ ५००० ते ६००० लोकं प्रबोधिनीच्या
                          वास्तूमध्ये हे प्रदर्शन बघायला येत असतात.
                        </p>
                        <p>
                          या वर्षीच्या कोविड-१९ रोगाच्या साथीमुळे नेहमीसारखा
                          गणेशोत्सव होणार नाही म्हणून या संकेतस्थळाच्या
                          माध्यमातून युवक विभागातील डॉ. विक्रम साराभाई दल
                          विज्ञान प्रदर्शन आयोजित करत आहे. आत्ताच्या परिस्थितीची
                          गरज म्हणून या वर्षीचा विषय “साथीच्या रोगांचे आयाम” असा
                          ठरवला आहे. दलातील युवकांनी मिळून अभ्यास करून साथीच्या
                          रोगांवर आधारित चित्रफिती तयार केल्या आहेत. या
                          प्रदर्शनात डोळ्याला न दिसणारा जंतू ते समाज आणि सरकार
                          यांचा प्रतिसाद अशा वेगवेगळ्या आयामांना स्पर्श करायचा
                          प्रयत्न केला आहे. या प्रदर्शनाची तयारी गेल्या तीन
                          महिन्यांपासून दलावरील युवक करत आहेत. विषय समजून
                          घेण्यासाठी त्यातील आशय हा विविध प्रश्न आणि त्याचे
                          उत्तर अशा विविध प्रश्नांच्या साखळीतून संकेतस्थळावर
                          मांडण्यात आला आहे. ही सर्व प्रश्नांची साखळी एकत्र
                          संकल्पना वृक्षात पाहायला मिळेल.
                        </p>
                        <p>
                          आम्हाला या विषयांचा अभ्यास करत असताना बरेच प्रश्न पडले
                          आणि त्या प्रश्नांची उत्तरं मिळवण्यासाठी आम्ही त्या
                          क्षेत्रात काम करणाऱ्या तज्ज्ञ व्यक्तींशी चर्चा केली.
                          या तज्ज्ञ व्यक्तींशी झालेल्या चर्चा आणि त्यांनी दिलेली
                          उत्तरे यांच्या देखील चित्रफिती संकेतस्थळावर उपलब्ध
                          आहेत. आपल्याला थेट उत्तरे मिळावीत, म्हणून आम्ही
                          विचारलेले प्रश्न काही चित्रफितींंमध्ये गाळलेलेही आहेत.
                        </p>
                        <p>
                          हे संकेतस्थळ बघताना तुम्हाला मुख्य पानावर वेगवेगळ्या
                          क्षेत्रांचे मुख्य प्रश्न दिसतील. एखादा प्रश्न निवडला
                          की तुम्हाला त्या प्रश्नांसंबंधीची चित्रफीत दिसणे चालू
                          होईल. असे एका मुख्य प्रश्नापासून त्याचे वेगवेगळे
                          उपप्रश्न आणि त्यांची उत्तरे त्या संबंधीची चित्रफीत
                          पाहून समजून घेणे असे या संकेतस्थळाचे स्वरूप आहे. पहिली
                          चित्रफित पाहून झाल्यावर तुम्हाला पुढची कोणती चित्रफित
                          बघायची याचे पर्याय चित्रफितीच्या जागी दिसतील. चित्रफित
                          चालू असताना तुम्हाला चित्रफितीच्या उजव्या बाजूला
                          साखळीतील पुढच्या चित्रफितींचे पर्याय दिसतील.
                          चित्रफितींच्या डाव्या बाजूला सर्व मुख्य आणि उपप्रश्न
                          तुम्हाला नेहमी दिसत राहतील, तिकडून कोणताही प्रश्न
                          तुम्ही निवडू शकता. याच बरोबर चित्रफितीतील अवघड
                          शब्दांचा अर्थ उजव्या बाजूला शब्दकोषात दिलेला आहे.
                          प्रत्येक चित्रफितीबाबत तुम्ही तुमचा प्रतिसाद नोंदवू
                          शकता.
                        </p>
                        <div>
                          हे प्रदर्शन तयार करण्यासाठी खालील सर्वांनी विविध
                          प्रकारे मदत केली, त्याबद्दल सर्वांचे आभार.
                        </div>

                        <ol style={{ marginTop: "15px" }}>
                          डॉ. विक्रम साराभाई दलावरील सर्व युवक व युवक विभागातील
                          इतर मार्गदर्शन करणारे दादा
                        </ol>
                        <ol> देणगीदारांनी आर्थिक साहाय्य केले</ol>
                        <ol>
                          तज्ज्ञ व्यक्तींनी त्यांच्या क्षेत्रातील अनुभव सांगून
                          आमचे मार्गदर्शन केले.
                        </ol>
                        <ol>
                          मुख्य चित्रफित संपादक तेजस शेंडे व साहाय्यक अश्विन
                          कोळेकर यांनी काम केले
                        </ol>

                        <ol>
                          <a
                            className="link-dark"
                            href="https://cereble.com/"
                            target="_blank"
                            style={{ marginRight: "5px" }}
                          >
                            Cereble Private Limited
                          </a>
                          या कंपनीने हे संकेतस्थळ बनवले.
                        </ol>

                        <ol>
                          सागर गावडे आणि आशुतोष रोकडे यांनी चित्रफिती मधील काही
                          चित्रे काढून दिली.
                        </ol>
                      </span>
                    </div>
                    <div
                      className="cell small-3 arrow-box"
                      onClick={() => nextPage(parseFloat("1"))}
                    >
                      <img
                        src="/images/NextArrow.svg"
                        alt=""
                        className="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Popup />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default MainQuestion;
