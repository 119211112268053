import React from "react";

const Menu = (props) => {
  var show = true;
  if (props.show && show) {
    var drawer = document.getElementsByClassName("drawer");
    // var button = document.getElementsByClassName("menu-button");
    //button[0].style.left= "77vw"

    drawer[0].style.right = "0";
    drawer[0].style.width = "55vw";
    var backdrop = document.getElementsByClassName("backDrop");
    backdrop[0].style.display = "block";
    // drawer[0].style.display="block";

    // drawer.style.backgroundColor="red"
  }

  const hideMenu = () => {
    var drawer = document.getElementsByClassName("drawer");
    // var button = document.getElementsByClassName("menu-button");
    //button[0].style.left= "77vw"

    drawer[0].style.width = "0";
    drawer[0].style.right = "-75vw";
    var backdrop = document.getElementsByClassName("backDrop");
    backdrop[0].style.display = "none";
    //  drawer[0].style.display="none";

    // drawer.style.backgroundColor="red"
  };

  const amchyaWishayi = () => {
    props.link.replace("/amchyaWishayi");
  };

  const etarUpakram = () => {
    props.link.replace("/etarupakram");
  };

  const sankalpanaWruksh = () => {
    props.link.replace("/sankalpanaWruksh");
  };

  const homePageHandler = () => {
    props.link.replace("/");
  };

  const mainQuestionPageHandler = () => {
    props.link.replace("/question");
  };

  return (
    <div>
      <div className="backDrop" onClick={hideMenu}></div>
      <div className="drawer">
        <span className="cross" onClick={hideMenu}>
          <img src="/images/cross.svg" />
        </span>

        <span className="drawer-option" onClick={homePageHandler}>
          मुख्य पान
        </span>
        <span className="drawer-option" onClick={mainQuestionPageHandler}>
          मुख्य प्रश्न
        </span>
        <span className="drawer-option" onClick={amchyaWishayi}>
          आमची ओळख
        </span>
        <span className="drawer-option" onClick={sankalpanaWruksh}>
          संकल्पना वृक्ष
        </span>
        <span className="drawer-option" onClick={etarUpakram}>
          इतर उपक्रम
        </span>

        <div className="socialMediaLinks">
          <a
            href="https://www.facebook.com/DrVikramSarabhaiDal/"
            target="blank"
            className="marginB23"
            onClick={hideMenu}
          >
            <img src="/images/fbIcon.svg" className="linkIcon" />
            फेसबुक
          </a>
          <a
            href="https://www.instagram.com/jp_vijnanadal/"
            target="blank"
            onClick={hideMenu}
          >
            <img src="/images/instaIcon.svg" className="linkIcon" />
            इंस्टाग्राम
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
