import React, { useState } from "react";
import $ from "jquery";
import Menu from "../components/menu";

const Shrink = (props) => {
  const [menu, showMenu] = useState(false);

  $(document).ready(function () {
    window.onscroll = function () {
      shrinktop();
    };
    shrinktop();
    function shrinktop() {
      var mainName = document.getElementsByClassName("website-name");
      var margins = document.getElementsByClassName("heading-row");
      var menuBar = document.getElementsByClassName("menuBar");

      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50 ||
        props.size === "small"
      ) {
        mainName[0].style.fontSize = "30px";
        margins[0].style.marginTop = "10px";
        margins[0].style.marginBottom = "10px";
        margins[1].style.marginTop = "10px";
        margins[1].style.marginBottom = "10px";
        margins[2].style.marginTop = "10px";
        margins[2].style.marginBottom = "10px";
        margins[3].style.marginTop = "10px";
        margins[3].style.marginBottom = "10px";
        menuBar[0].style.marginTop = "9px";
      } else {
        mainName[0].style.fontSize = "40px";
        margins[0].style.marginTop = "20px";
        margins[0].style.marginBottom = "20px";
        margins[1].style.marginTop = "20px";
        margins[1].style.marginBottom = "20px";
        margins[2].style.marginTop = "20px";
        margins[2].style.marginBottom = "20px";
        margins[3].style.marginTop = "20px";
        margins[3].style.marginBottom = "20px";
        menuBar[0].style.marginTop = "18px";
      }
    }

    var backDrop = document.getElementsByClassName("backDrop");
    if (backDrop[0].style.display) {
      showMenu(false);
    }
  });

  const amchyaWishayi = () => {
    props.link.replace("/amchyaWishayi");
  };

  const etarUpakram = () => {
    props.link.replace("/etarUpakram");
  };

  const homePageHandler = () => {
    props.link.replace("/");
  };

  const sankalpanaWruksh = () => {
    props.link.replace("/sankalpanaWruksh");
  };

  const mainQuestionPageHandler = () => {
    props.link.replace("/question");
  };

  return (
    <div className="cell small-24 positionFixed">
      <div className="grid-x align-right">
        <div
          className="cell auto text-left align-self-middle"
          onClick={homePageHandler}
        >
          <span className="heading-row heading-row-L website-name">
            ज्ञान प्रबोधिनी युवक विभाग
            <div className="sub-heading">
              डॉ. विक्रम साराभाई दल विज्ञान प्रदर्शन
            </div>
          </span>
        </div>
        <div className="menuBar">
          <div className="grid-x">
            <div className="cell shrink text-center hide-for-small-only heading-row align-self-middle">
              <span className="headerLink" onClick={homePageHandler}>
                <i
                  class="fa fa-home"
                  aria-hidden="true"
                  style={{ fontSize: "22px" }}
                ></i>
              </span>
            </div>

            <div className="cell shrink text-center hide-for-small-only heading-row align-self-middle">
              <span className="headerLink" onClick={mainQuestionPageHandler}>
                <i class="fas fa-th" style={{ fontSize: "22px" }}></i>
              </span>
            </div>
            <div className="cell shrink text-center hide-for-small-only heading-row align-self-middle">
              <span
                className="headerLink"
                onClick={sankalpanaWruksh}
                style={{ marginTop: "-4px" }}
              >
                संकल्पना वृक्ष
              </span>
            </div>
            <div className="cell shrink text-center hide-for-small-only heading-row align-self-middle">
              <span className="headerLink" onClick={etarUpakram}>
                इतर उपक्रम
              </span>
            </div>
            <div className="cell shrink text-center hide-for-small-only">
              <span
                className="heading-row pratisad-text amchyaWishayi"
                onClick={amchyaWishayi}
              >
                आमची ओळख
              </span>
            </div>
          </div>
        </div>
        <div className="cell shrink text-center menuIcon align-self-middle">
          <span onClick={() => showMenu(true)} className="menu-button">
            <img
              src="/images/menuIcon.svg"
              alt="menuIcon"
              className="menuButtonIcon"
            />
          </span>
        </div>
      </div>

      <Menu show={menu} link={props.link} />
    </div>
  );
};

export default Shrink;
