import React, { useState } from 'react'
import { useEffect } from 'react'
import CsvDownload from 'react-json-to-csv'
import { db } from '../../database/database'

const AdminPanel = () => {
	const [websiteData, setWebsiteData] = useState('')

	const [password, setPassword] = useState('prabodhini')

	const [enteredPassword, setEnteredPassword] = useState(0)

	const validatePassword = () => {
		if (enteredPassword === password) {
			alert('Login successfull')
		} else {
			setWebsiteData('')
			alert('Failed !!!! Please Enter Correct password !')
		}
	}

	useEffect(() => {
		db.collection('feedbacks')
			.orderBy('receivedOn', 'desc')
			.get()
			.then(function (querySnapshot) {
				const pratisads = []
				querySnapshot.forEach(function (doc) {
					const pratisad = doc.data()
					pratisads.push(pratisad)
					setWebsiteData(pratisads)
				})
			})
	}, [])

	return (
		<div className='grid-x password-box align-center-middle'>
			<div className='cell small-24 '>
				<h3 className='text-center'>You need password to access this page</h3>
				<div className='text-center '>
					Your password :
					<input
						type='password'
						required='required'
						className='pratisadFormInputSmallBox custom-align-center'
						onChange={(e) => setEnteredPassword(e.target.value)}
					/>
				</div>
				<div onClick={validatePassword}>
					<CsvDownload
						data={enteredPassword === password ? websiteData : ''}
						filename='prabodhini.csv'
						className='popupButton custom-align-center marginT75'
					>
						Download
					</CsvDownload>
				</div>
			</div>
		</div>
	)
}

export default AdminPanel
