import React from 'react'
import HeadingOfWebsite from './headingOfWebsite'
import Popup from './popup'

import Footer from './Footer/Footer'

const AmchyaWishayi = (props) => {
	return (
		<div className='page-1'>
			<HeadingOfWebsite link={props.history} />
			<div className='backDrop'></div>
			<div className='pageMargin'>
				<div className='grid-x'>
					<div className='cell small-3'></div>
					<div className='cell small-24 medium-18'>
						<div className='grid-x'>
							<div className='small-24 amchyaWishayiBox'>
								<div className='amchyaWishayiHeading'>
									<b>आमची ओळख</b>
								</div>
								<div className='amchyaWishayiText marginCustomForAmchyawishayi '>
									<div className='para'>
										‘रूप पालटू देशाचे’ हे ध्येय ठेवून काम करणाऱ्या ज्ञान
										प्रबोधिनीमध्ये सुरुवातीपासून समाज-परिवर्तनासाठी युवकांची घडण
										व त्यांची संघटना बांधण्याचे काम युवक विभाग करत आला आहे. हे
										काम करण्यासाठी युवकांना ज्ञान-संपादनासाठी स्वावलंबी बनवणारे,
										विविध कौशल्यं मिळवायला शिकवणारे, वृत्ती घडवणारे, प्रेरणा
										जागवणारे आणि ध्येय दाखवणारे व संघटनेचा भाग बनवणारे म्हणजे
										स्वामी विवेकानंदांच्या कल्पनेतले ’मनुष्यघडणीचे’ शिक्षण
										मिळावे हे उद्दिष्ट समोर ठेवून या विभागात वेगवेगळी दले चालवली
										जातात. सुरुवातीला फक्त ‘क्रीडा’ हा मूळ विषय ठेवून दले
										चालायची, पण गेल्या १५ वर्षापासून ‘विज्ञान’ या विषयाची मूळ
										विषयात भर पडली व ‘विज्ञान दले’ सुरु झाली.
									</div>
									<div className='para'>
										विज्ञान दलांमध्ये प्रत्येक गोष्टीकडे वैज्ञानिक दृष्टीकोनातून
										बघण्याची वृत्ती तयार होते. दर आठवड्यात १-२ वेळा भेटून
										विज्ञानाच्या वेगवेगळ्या विषयांवर चर्चा करणे, प्रयोग शाळेत
										प्रयोग करणे, प्रकल्प करणे असे नियोजन असते. विज्ञान दल म्हणजे
										फक्त आशयाचे प्रशिक्षण नव्हे; विज्ञानाची वृत्ती म्हणजे प्रश्न
										विचारणे, निरीक्षणे करणे, प्रयोग स्वत: रचणे आणि माहितीच्या
										विश्लेषणातून निष्कर्ष काढणे. शालेय अभ्यासक्रमांच्या पलीकडची
										ही कौशल्ये विज्ञान दलांमध्ये शिकवली जातात. कोणीतरी मोठ्याने
										शिकवणे याबरोबरच मित्रांकडून किंवा स्वतः काम करता करता
										अनुभवातूनसुद्धा शिकायला पाहिजे हे विज्ञान दलात कळते. नियमित
										भेटींच्या पलीकडे शिक्षणासाठी वेगवेगळ्या उपक्रमांची योजना
										केली जाते. हे विज्ञान प्रदर्शन अशा अनेक उपक्रमांपैकी एक आहे.
										अभ्यास शिबीरे, विज्ञान प्रकल्प पद्धती शिबीर, अभ्यास दौरा इ.
										असे अनेक उपक्रम असतात.
									</div>
									<div className='para'>
										<p>
											<b>शालेय वयीन मुलग्यांसाठी विज्ञान दले </b>
										</p>
										<p>
											२००४ साली सदाशिव पेठेतील ज्ञान प्रबोधिनी भवनामध्ये पहिले
											विज्ञान दल, डॉ. मेघनाद साहा दल, सुरू झाले. किशोर गट (५वी
											ते ७वी) व कुमार गट (८वी ते १०वी) अशा दोन गटांमध्ये ही
											विज्ञान दले होतात. या विज्ञान दलांचा विस्तार होत होत सध्या
											विज्ञान दलांच्या शालेय वयोगटासाठीच्या तीन शाखा चालतात.
											आपल्यालाही सहभागी व्हायचे असल्यास अवश्य संपर्क साधावा.
										</p>
									</div>
									<div className='para'>
										<p>
											१)
											<span className='pl-md'>
												<b>डॉ. मेघनाद साहा दल (विज्ञान दल- सदाशिव पेठ)</b>
											</span>
										</p>
										<p className='pl-lg'>
											हे सर्वात पहिले विज्ञान दल. २००४ साली सुरुवात झाली व गेली
											१७ वर्षे चालू आहे. तीन सुसज्ज प्रयोगशाळांमध्ये
											विद्यार्थ्यांना शिकण्याची संधी. <br />
											<b className='pr-md'>ठिकाण:</b> ज्ञान प्रबोधिनी भवन,
											सदाशिव पेठ
											<br />
											<b className='pr-md'>संपर्क:</b> हृषीकेश कुलकर्णी
											८२०८५३५४५३ (५वी ते ७वी) , पार्थ डांगी ७२६१९९४१५१ (८वी ते
											१०वी)
											<br />
											<b className='pr-md'>ई-मेल:</b>
											meghnadsaha.dal@jnanaprabodhini.org
										</p>
									</div>
									<div className='para'>
										<p>
											२)
											<span className='pl-md'>
												<b>कोथरूड विज्ञान दल</b>
											</span>
										</p>
										<p className='pl-lg'>
											२०१९ साली कोथरूड मध्ये सुरुवात. सर्वात नवे विज्ञान दल.
											सोसायटीच्या हॉलमध्येही उत्तम प्रयोग साहित्यासहित चालणारे
											दल.
											<br />
											<b className='pr-md'>ठिकाण:</b> शिल्पा सोसायटी, रामबाग
											कॉलनी, कोथरूड
											<br />
											<b className='pr-md'>संपर्क:</b> हृषीकेश कुलकर्णी
											८२०८५३५४५३
										</p>
									</div>
									<div className='para'>
										<p>
											३)
											<span className='pl-md'>
												<b>गोखलेनगर विज्ञान दल</b>
											</span>
										</p>
										<p className='pl-lg'>
											२०१८ व १९ साली भागात विज्ञान प्रदर्शने घेऊन २०१९ पासून
											विज्ञान दलाला सुरुवात. प्रदर्शन, स्पर्धा अशी माध्यमे
											वापरून विज्ञान शिक्षण.
											<br />
											<b className='pr-md'>ठिकाण:</b> किलबिल शाळा, गोखलेनगर
											<br />
											<b className='pr-md'>संपर्क:</b> यश शहा ८३७८९७१९००, शिवम
											शिंदे ९२८४०५२०७६
										</p>
									</div>

									<div className='para'>
										<p>
											<b>महाविद्यालयीन युवकांसाठीचे विज्ञान दल</b>
										</p>
										<p>डॉ. विक्रम साराभाई दल</p>
										<p>
											२०१४ पासून सुरु झालेले हे दल महाविद्यालयीन गटातील
											युवकांच्या मनुष्यघडणीचे व विज्ञान विषयक आशय आणि
											प्रक्रियेच्या शिक्षणाचे काम करत आहे. यासाठी विविध उपक्रम व
											अनुभव देण्याची योजना करणे आणि यातून युवकांचे प्रशिक्षण
											घडवून आणणे आणि असे प्रशिक्षण देणारे युवक घडवणे हे या दलाचे
											उद्दिष्ट आहे. हे दल दर बुधवारी रात्री ८.३० ते १०.३० या
											वेळेत प्रबोधिनीच्या सदाशिव पेठेतील वास्तूत चालते. याच
											बरोबर दर शनिवारी दलातील युवकांसाठी अभ्यासिका असते ज्यात
											एकत्र अभ्यास व अभ्यासाची तंत्रे शिकणे यावर भर दिला जातो.
											महाविद्यालयांमधले प्रकल्प हे साचेबद्ध पद्धतीने न करता,
											विचारपूर्वक करणे; जगभरातील नवनवीन शाखांमधील अद्ययावत
											संशोधनाचा अभ्यास करणे; आणि कोणत्याही शास्त्राला मूलभूत
											प्रश्न विचारत ते खोलवर जाऊन शिकणे असे या ठिकाणी चालणाऱ्या
											विज्ञान-विषयक चर्चांचे वैशिष्ट्य आहे.
										</p>
										<p>
											सर्व विज्ञान दलांच्या अनुभवांवरून या विज्ञान दलांचे
											मार्गदर्शक संशोधनात्मक प्रकल्पांचे प्रशिक्षण देण्यासाठी
											महाराष्ट्र, आसाम व तेलंगण अशा राज्यांमधील शाळांमध्ये
											प्रशिक्षणासाठी जात असतात. दलाचे अनेक विद्यार्थी
											परदेशांमध्ये विज्ञान क्षेत्रातील उच्च शिक्षण घेत आहेत.
											त्यांच्यापैकी काहींनी गुरुदक्षिणा म्हणून या प्रदर्शनाला
											देणगी दिलेली आहे.
										</p>
										<b className='pr-md'>ठिकाण:</b> ज्ञान प्रबोधिनी भवन, सदाशिव
										पेठ
										<br />
										<b className='pr-md'>संपर्क:</b> सोहम डांगे - ९९७०२०७७१८
										<div className='pr-60'>संकेत भंडारे - ८९९९८९१४७२</div>
										<div className='pr-60'>वरुण देशमुख - ९४२०६५६२९०</div>
										<b className='pr-md'>ई-मेल:</b>
										drvikramsarabhaidal@gmail.com
										<br />
										<b className='pr-md'>Facebook:</b>
										<a
											className='link-light'
											href='https://www.facebook.com/DrVikramSarabhaiDal'
											target='_blank'
										>
											Dr Vikram Sarabhai Dal
										</a>
									</div>
									<div className='para'>
										<p>
											<b>इतर माहितीसाठी</b>
										</p>
										<p>युवक विभाग</p>
										<a
											className='link-light'
											href='https://www.facebook.com/jnanaprabodhini.org/'
											target='_blank'
										>
											ज्ञान प्रबोधिनी युवक विभाग - Facebook
										</a>
										<br />
										<a
											className='link-light'
											href='mailto:yuvak.vibhag@jnanaprabodhini.org'
											target='_blank'
										>
											yuvak.vibhag@jnanaprabodhini.org
										</a>
										<p></p>
										<p>ज्ञान प्रबोधिनी</p>
										<a
											className='link-light'
											href='https://www.jnanaprabodhini.org/'
											target='_blank'
										>
											ज्ञान प्रबोधिनी - Website
										</a>
										<br />
										<a
											className='link-light'
											href='https://www.youtube.com/channel/UCzW5ZlenyIWv3x_N0UqynEQ'
											target='_blank'
										>
											ज्ञान प्रबोधिनी - YouTube
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Popup />
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default AmchyaWishayi
