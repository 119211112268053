import React, { Component } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Home from './components/homePage/Home'
import MainQuestion from './components/mainQuestion/MainQuestion'
import SubQuestion from './components/mainQuestion/subQuestion/SubQuestion'
import amchyaWishayi from './components/amchyaWishayi'
import etarUpakram from './components/etarUpakram'
import SankalpWruksh from './components/SankalpWruksh'
import AdminPanel from './components/AdminPanel/AdminPanel'

class App extends Component {
	render() {
		return (
			<>
				<Route path='/' exact component={Home} />
				<Route path='/question' exact component={MainQuestion} />
				<Route path='/:id1/:id2' component={SubQuestion} />
				<Route path='/amchyaWishayi' exact component={amchyaWishayi} />
				<Route path='/etarUpakram' exact component={etarUpakram} />
				<Route path='/sankalpanaWruksh' exact component={SankalpWruksh} />
				<Route path='/adminpanel' exact component={AdminPanel} />
			</>
		)
	}
}

export default App
